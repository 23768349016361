import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
// import Week from "../components/week"
// import Schedule from "../components/schedule"
import MiniLayout from "../../components/minimalLayout"


const Space = ({data}) => {

  return (
    <Layout>

      <MiniLayout>
        <h2>Readings about: <i>Cao Fei</i> and <i>Eva and Franco Mattes</i></h2>
        <p>Reading 1: <i>The Different Worlds of Cao Fei</i> by Alice Ming Wai Jim. (<a target="_blank" href="https://drive.google.com/file/d/1x8wlYdJ3zUADc9o24ZhImgDbFEU-6M21/view?usp=sharing">NYU access</a>)</p>
        <p>Reading 2: <i>Now You're in my Computer: Performing in the Network's Theater of Visibility</i> by Megan Driscoll. (<a target="_blank" href="https://drive.google.com/file/d/1vbdqc2SYn0QmiCVIiwozFzPwg8hdhi_Z/view?usp=sharing">NYU access</a>)</p>

        <br></br>
        <p>> The Different <b>WORLDS</b> of Cao Fei</p>
        <p>> Now You're <b>IN</b> my Computer</p>
        <p><i>How is spatiality consituted in the artists' works that are discussed?</i></p>
        <p>Read both readings, take notes and write a short response (300-500). If available, add further associations that come to your mind while reading/thinking.</p>



        <br></br>
        <p>1) Complete your writing in a markdown file (.md) inside your repository.</p>
        <p>2) When done, push your work to your GitHub account and <a target="_blank" href="https://github.com/leoneckert/abc-browser-circus/wiki">submit a link at our class Wiki.</a></p>
      </MiniLayout>
    </Layout>
  )
}



export default Space
